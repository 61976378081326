// ----- Progress Tracker Animations -----
// Animation show after click using pure CSS based on a technique - https://ghinda.net/article/css-ripple-material-design/ and the Material design ripple - https://getmdl.io/components/#buttons-section

// Ripple elements mixin
@mixin ripple-element() {
  content: "";
  display: block;
  width: $marker-size;
  height: $marker-size;
  position: absolute;
  top: $marker-size-half;
  left: $marker-size-half;
  z-index: 30;
  background: $ripple-color;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0); // Initial state, hides the effect when not animating
  visibility: hidden; // Hides element so it does not animate on page load
}

@mixin ripple-element-active() {
  visibility: visible; // Shows element when it is focused
}

// Animation Keyframes
@keyframes scale-up {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }

  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
}



// ----- Ripple effect when marker is clicked -----
.anim-ripple, .anim-ripple-large, .anim-ripple-splash {

  .progress-link::before {
    @include ripple-element();
  }

  .progress-link:not(:active)::before {
    animation: scale-up $animation-duration ease-out;
  }

  .progress-link:focus::before {
    @include ripple-element-active();
  }

}

.anim-ripple, .anim-ripple-large, .anim-ripple-splash, .anim-ripple-double {

  &.progress-tracker--center, .progress-tracker--center & {

    .progress-link {
      &::before, &::after {
        left: 50%;
      }
    } 
  }

  &.progress-tracker--right, .progress-tracker--right & {

    .progress-link {
      &::before, &::after {
        left: calc(100% - #{$marker-size-half});
      }
    }

  }

}


// ----- Large ripple with splash -----
.anim-ripple-splash {

  .progress-link::before {
    width: $marker-size*2;
    height: $marker-size*2;
    box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.35);
  }

}

// ----- Double ripple effect, similar to an actual water ripple -----
.anim-ripple-double {

  .progress-link {
    &::before, &::after {
      @include ripple-element();
      background: none;
      border: 3px solid $ripple-color;
    }
  }

  .progress-link:not(:active)  {
    &::before, &::after {
      animation: scale-up $animation-duration ease-out 0s;
    }
    
    &::after {
      animation-delay: $animation-duration*0.5;
    }
  }

  .progress-link:focus  {
    &::before, &::after {
      @include ripple-element-active();
    }
  }

}

.anim--large {
  .progress-link {
    &::before, &::after {
      width: $marker-size*2;
      height: $marker-size*2;
    }
  }
}


// ----- Fill up the path between markers when it becomes active -----
.anim--path {

  .progress-marker {

    &::after {
      background-image: linear-gradient(to right, $color-path 50%, $color-path-complete 50%);
      background-size: 200% 100%;
      background-position: 0% 100%;
      transition: background-position $animation-duration ease-out;
    }

    .progress-step.is-complete &::after {
      background-position: -100% 100%;
    }

  }



    .progress-step.is-complete .progress-marker::after {
      background-position: -100% 100%;
    }


}
