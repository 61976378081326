
//
// Range slider
//

.ng5-slider {
  .ng5-slider-pointer {
    background-color: $primary !important;
    width: 18px !important;
    height: 18px !important;
    top: -7px !important;
    outline: none !important;

    &:after {
      top: 5px !important;
      left: 5px !important;
    }
  }

  .ng5-slider-active {
    &:after {
      background-color: $white !important;
    }
  }

  .ng5-slider-tick {
    left: -3px !important;
  }

  .ng5-slider-tick-value {
    left: 1px !important;
    top: -25px !important;
  }

  .ng5-slider-tick-legend {
    top: 14px !important;
    text-transform: capitalize;
    font-size: 0.7rem;
  }

  .ng5-slider-selection {
    background: $primary !important;
  }
}

// We need to use ::ng-deep to overcome view encapsulation
.custom-slider .ngx-slider .ngx-slider-bar {
  background: #d8e0f3 !important;
}
.custom-slider .ngx-slider .ngx-slider-selection {
  background: $primary !important;
}
.custom-slider.danger .ngx-slider .ngx-slider-selection {
  background: $danger !important;
}
.custom-slider.moderate .ngx-slider .ngx-slider-selection {
  background: $success !important;
}
.custom-slider .danger .ngx-slider .ngx-slider-selection {
  background: $danger !important;
}
.custom-slider .moderate .ngx-slider .ngx-slider-selection {
  background: $danger !important;
}
.custom-slider .ngx-slider .ngx-slider-pointer {
  background-color: $primary !important;
  width: 18px !important;
  height: 18px !important;
  top: -7px !important;
  outline: none !important;

  &:after {
    top: 5px !important;
    left: 5px !important;
  }
}
.custom-slider.danger .ngx-slider .ngx-slider-pointer {
  background-color: $danger !important;
}
.custom-slider.moderate .ngx-slider .ngx-slider-pointer {
  background-color: $success !important;
}
.custom-slider .ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
  background-color: $white !important;
}

.custom-slider .ngx-slider .ngx-slider-tick {
  left: -3px !important;
}

.custom-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: $white;
}
