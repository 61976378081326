.form-check-label {
  color: #495057 !important;
}
.ng-dropdown-panel-items {
  min-width: 350px;
  border: 0.5px #b3b3b3 solid;
}
.td-no-border {
  border: none !important;
}
.dropdown-menu.show {
  z-index: 1000 !important;
}
.card-body.card-body-short {
  padding-top: 5px;
  padding-bottom: 0px;
}
.dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
  min-width: 300px !important;
  background-attachment: local, local, scroll, scroll;
}
.dropdown-item {
  white-space: nowrap;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: $primary !important;
}
.cursor-disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
.narrow-column {
  width: 40px;
  max-width: 40px;
}
.non-resizable {
  resize: none;
}
