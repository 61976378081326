.page-content {
    @media screen and (max-width: 576px) {
        padding: calc(#{$header-height} + #{$grid-gutter-width}) 5px $footer-height 5px;
    }
}

.main-content.minimum {
    margin-left: 0px !important;
}
.page-content.minimum {
    padding: calc(0px + 24px) calc(24px / 2) 60px calc(24px / 2) !important;
}
.footer.minimum {
    left: 0 !important;
}

.card-body {
    @media screen and (max-width: 576px) {
        padding: 0.5rem 0.5rem;
    }
}

.card {
    @media screen and (max-width: 576px) {
        margin-bottom: 2px;
    }
}

// dropdown
.dropdown-toggle {
    &:after {
        display: none;
    }
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}

.form-check-label>.card-title {
    margin-top: 4px;
}

.card .card-no-margin {
    margin-bottom: 0 !important;
}

.card .no-side-border {
    border-left: none !important;
    border-right: none !important;
}

.column-header {
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

.sticky-table-row {

    th:first-child,
    td:first-child {
        position: sticky;
        left: 0px;
        background-color: #fff !important;
    }

    th.sticky-column,
    td.sticky-column {
        position: sticky;
        left: 0px;
        background-color: #fff !important;
    }
}

// toastr
.toast-success {
    background-color: $primary !important;
    border-color: $primary !important;
}

.toast-info {
    background-color: $lazur !important;
    border-color: $lazur !important;
}

.toast-error {
    background-color: $danger !important;
    border-color: $danger !important;
}

.toast-warning {
    background-color: $warning !important;
    border-color: $warning !important;
}

// select2 invalid class
.is-invalid .ng-select .ng-select-container {
    border: 1px solid $danger !important;
}

.is-invalid .ng-select-container {
    border: 1px solid $danger !important;
}

// multiple select invalid class
.is-invalid .dropdown button {
    border: 1px solid $danger !important;
}

// scrollable table
.table-scrollable {
    overflow-y: auto;
    height: 400px;
}

.table-scrollable table {
    overflow-y: auto;
    overflow-x: hidden;
}

.table-scrollable thead th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    // background: #fff !important;
    background: #FFFFFF;
}

.table-scrollable thead tr.first th,
thead tr.first td {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: #FFFFFF;
    z-index: 2;
}

.table-scrollable thead tr.second th,
thead tr.second td {
    position: sticky;
    position: -webkit-sticky;
    top: 36px !important;
    background: #FFFFFF;
    z-index: 2;
}

.table-scrollable thead td {
    position: sticky;
    top: 0;
    background: #FFFFFF;
}

@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

/* Ratings widget */
.rate {
    display: inline-block;
    border: 0;
}

/* Hide radio */
.rate>input {
    display: none;
}

/* Order correctly by floating highest to the right */
.rate>label {
    float: right;
}

/* The star of the show */
.rate>label:before {
    display: inline-block;
    font-size: 1rem;
    padding: .3rem .2rem;
    margin: 0;
    cursor: pointer;
    font-family: FontAwesome;
    content: "\f005 ";
    /* full star */
}

/* Half star trick */
.rate .half:before {
    content: "\f089 ";
    /* half star no outline */
    position: absolute;
    padding-right: 0;
}

/* progress tracker */
.progress-step .progress-marker::after {
    z-index: 1;
}

.progress-step.is-complete .progress-marker::before,
.progress-step.is-progress .progress-marker::before {
    background-color: $primary !important;
}

.progress-step.is-active .progress-marker::before,
.progress-step.is-progress .progress-marker::before {
    background-color: #19e3bb !important;
}

.progress-step:hover .progress-marker::before {
    background-color: #72dab4 !important;
}

.progress-marker::after {
    height: 1px !important;
}

.progress-tracker {
    margin: 20px auto !important;
}

/* progress bar */
.progress-bar.is-complete {
    background-color: $primary !important;
}
.progress-bar.is-active {
    background-color: #19e3bb !important;
}
.progress-bar:hover {
    background-color: #72dab4 !important;
}

//pace-js
.pace {
    -webkit-pointer-events: none;
    pointer-events: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.pace-inactive {
    display: none;
}

.pace .pace-progress {
    background: #1ab394;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px;
}

/* ngx-chips custom styles */
$foundation-primary: #1779ba;
$foundation-primary-dark: darken($foundation-primary, 10%);

// this is the container's theme
$foundation-theme: (container-border-bottom: 1px solid $foundation-primary);

// this is the tag's theme
$foundation-tag-theme: (background: $foundation-primary,
    background-focused: $foundation-primary-dark,
    background-active: $foundation-primary-dark,
    background-hover: $foundation-primary-dark,
    color: #fff,
    color-hover: #fff,
    border-radius: 2px);

// this is the delete icon's theme
$foundation-icon-theme: (fill: #fff,
    fill-focus: #eee,
    transition: all 0.35s);

// apply theme to the container
::ng-deep tag-input .ng2-tag-input.foundation-theme {
    @include tag-input-theme($foundation-theme);
}

// apply theme to the tags
::ng-deep tag-input .ng2-tag-input.foundation-theme tag {
    @include tag-theme($foundation-tag-theme);
}

// apply theme to the delete icon
::ng-deep tag-input .ng2-tag-input.foundation-theme tag delete-icon {
    @include icon-theme($foundation-icon-theme);
}

.container-xl,
.container-xxl {
    @media (min-width: 1200px) {
        max-width: 1320px !important;
    }
}

.modal {
    z-index: 1055;
}
.modal-open {
    overflow: hidden;
}

.highlight-yellow {
    background-color: yellow !important;
}

.list-style-none {
    list-style: none;
    padding: 0;
}

ngb-typeahead-window {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-md,
.container-sm {
    @media screen and (max-width: 991px) {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
}

.horizontal-scrollable>.row {
    overflow: scroll;
    white-space: nowrap;
}

.horizontal-scrollable>.row>.col-xs-4 {
    display: inline-block;
    float: none;
}

.scrolling-wrapper{
	overflow-x: auto;
}

/* navigation tab */
.nav-tabs .nav-link.active {
    // background-color: $primary;
    // color: #FFFFFF;
}

.nav-tabs .nav-link.is-invalid {
    // background-color: $danger;
    // color: #FFFFFF;
    color: $danger;
}

.nav-tabs-custom .nav-item .nav-link.is-invalid {
    color: $danger;
}

.bg-grey {
    background-color: #F5F5F8 !important;
}

.email-template-logo {
    width: 100px;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.fr-second-toolbar a {
    display: none !important;
}

.fr-popup.fr-desktop.fr-active {
    z-index: 1100 !important;
}

// ng2-tag-input
.ng2-tag-input.ng2-tag-input--focused {
    border-bottom: 2px solid $primary !important;
}

.btn-xs, btn-group-xs > .btn {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}
