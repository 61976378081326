/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-line-spin-fade,
.la-line-spin-fade > div {
    position: relative;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.la-line-spin-fade {
    display: block;
    font-size: 0;
    color: #fff;
}

.la-line-spin-fade.la-dark {
    color: #333;
}

.la-line-spin-fade > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
}

.la-line-spin-fade {
    width: 32px;
    height: 32px;
}

.la-line-spin-fade > div {
    position: absolute;
    width: 2px;
    height: 10px;
    margin: 2px;
    margin-top: -5px;
    margin-left: -1px;
    border-radius: 0;
    -webkit-animation: line-spin-fade 1s infinite ease-in-out;
       -moz-animation: line-spin-fade 1s infinite ease-in-out;
         -o-animation: line-spin-fade 1s infinite ease-in-out;
            animation: line-spin-fade 1s infinite ease-in-out;
}

.la-line-spin-fade > div:nth-child(1) {
    top: 15%;
    left: 50%;
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-animation-delay: -1.125s;
       -moz-animation-delay: -1.125s;
         -o-animation-delay: -1.125s;
            animation-delay: -1.125s;
}

.la-line-spin-fade > div:nth-child(2) {
    top: 25.2512626585%;
    left: 74.7487373415%;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-animation-delay: -1.25s;
       -moz-animation-delay: -1.25s;
         -o-animation-delay: -1.25s;
            animation-delay: -1.25s;
}

.la-line-spin-fade > div:nth-child(3) {
    top: 50%;
    left: 85%;
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-animation-delay: -1.375s;
       -moz-animation-delay: -1.375s;
         -o-animation-delay: -1.375s;
            animation-delay: -1.375s;
}

.la-line-spin-fade > div:nth-child(4) {
    top: 74.7487373415%;
    left: 74.7487373415%;
    -webkit-transform: rotate(135deg);
       -moz-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
         -o-transform: rotate(135deg);
            transform: rotate(135deg);
    -webkit-animation-delay: -1.5s;
       -moz-animation-delay: -1.5s;
         -o-animation-delay: -1.5s;
            animation-delay: -1.5s;
}

.la-line-spin-fade > div:nth-child(5) {
    top: 84.9999999974%;
    left: 50.0000000004%;
    -webkit-transform: rotate(180deg);
       -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
         -o-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-animation-delay: -1.625s;
       -moz-animation-delay: -1.625s;
         -o-animation-delay: -1.625s;
            animation-delay: -1.625s;
}

.la-line-spin-fade > div:nth-child(6) {
    top: 74.7487369862%;
    left: 25.2512627193%;
    -webkit-transform: rotate(225deg);
       -moz-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
         -o-transform: rotate(225deg);
            transform: rotate(225deg);
    -webkit-animation-delay: -1.75s;
       -moz-animation-delay: -1.75s;
         -o-animation-delay: -1.75s;
            animation-delay: -1.75s;
}

.la-line-spin-fade > div:nth-child(7) {
    top: 49.9999806189%;
    left: 15.0000039834%;
    -webkit-transform: rotate(270deg);
       -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
         -o-transform: rotate(270deg);
            transform: rotate(270deg);
    -webkit-animation-delay: -1.875s;
       -moz-animation-delay: -1.875s;
         -o-animation-delay: -1.875s;
            animation-delay: -1.875s;
}

.la-line-spin-fade > div:nth-child(8) {
    top: 25.2506949798%;
    left: 25.2513989292%;
    -webkit-transform: rotate(315deg);
       -moz-transform: rotate(315deg);
        -ms-transform: rotate(315deg);
         -o-transform: rotate(315deg);
            transform: rotate(315deg);
    -webkit-animation-delay: -2s;
       -moz-animation-delay: -2s;
         -o-animation-delay: -2s;
            animation-delay: -2s;
}

.la-line-spin-fade.la-sm {
    width: 16px;
    height: 16px;
}

.la-line-spin-fade.la-sm > div {
    width: 1px;
    height: 4px;
    margin-top: -2px;
    margin-left: 0;
}

.la-line-spin-fade.la-2x {
    width: 64px;
    height: 64px;
}

.la-line-spin-fade.la-2x > div {
    width: 4px;
    height: 20px;
    margin-top: -10px;
    margin-left: -2px;
}

.la-line-spin-fade.la-3x {
    width: 96px;
    height: 96px;
}

.la-line-spin-fade.la-3x > div {
    width: 6px;
    height: 30px;
    margin-top: -15px;
    margin-left: -3px;
}

/*
 * Animation
 */
@-webkit-keyframes line-spin-fade {
    50% {
        opacity: .2;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes line-spin-fade {
    50% {
        opacity: .2;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes line-spin-fade {
    50% {
        opacity: .2;
    }
    100% {
        opacity: 1;
    }
}
@keyframes line-spin-fade {
    50% {
        opacity: .2;
    }
    100% {
        opacity: 1;
    }
}
